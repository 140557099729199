import { clsx } from 'clsx';
import { ButtonLink } from '~/components/button.tsx';
import { H3 } from '~/components/typography.tsx';

function SecondActionSection() {
  return (
    <div className={clsx('relative', 'py-2 md:py-4')}>
      <div className="absolute inset-0">
        <div className="mx-auto h-full w-full rounded-sm bg-red-300 dark:bg-zinc-700" />
      </div>
      <div
        className={clsx(
          'relative grid grid-cols-12 gap-x-4 lg:grid-cols-12 lg:gap-x-6',
          'mx-auto max-w-7xl gap-y-4 lg:gap-y-6'
        )}
      >
        <div className="col-span-12 mb-8 mt-8 flex flex-col items-center gap-8 lg:mb-0 lg:mt-0 lg:flex-row lg:justify-center">
          <H3
            id="second-action"
            variant="ternary"
            className="text-center text-3xl font-normal leading-tight text-white lg:text-left"
          >
            ¿Prefieres tener una consulta? Agenda una ahora mismo.
          </H3>
          <ButtonLink to="/cita-medica-virtual" variant="primary" prefetch="intent" size="large">
            AGENDAR AHORA
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}

export { SecondActionSection };

import { Grid } from '~/components/grid.tsx';
import { H2, H4, H5 } from '~/components/typography.tsx';
import { getImgProps, images } from '~/images.tsx';

function UnderstandSolveIssueSection() {
  return (
    <>
      <div className="flex justify-center lg:pb-20 ">
        <H2 className="max-w-5xl text-center">Entiende al fin cómo resolver tu problema vaginal</H2>
      </div>
      <Grid>
        <div className="col-span-full mt-12 flex flex-col justify-center lg:col-span-5 lg:mt-0">
          <H4 className="mt-6 text-center md:text-left">Imágenes reales</H4>
          <H5 variant="secondary" className="mt-4 px-8 text-center md:px-0 md:text-left" as="p">
            Que mejor manera de aprender que ver una imagen real de un problema real.
          </H5>

          <H4 className="mt-6 text-center md:text-left">Información comprobada</H4>
          <H5 variant="secondary" className="mt-4 px-8 text-center md:px-0 md:text-left" as="p">
            Nuestra información se basa únicamente en evidencia científica comprobada.
          </H5>

          <H4 className="mt-6 text-center md:text-left">Contenido didáctico</H4>
          <H5 variant="secondary" className="mt-4 px-8 text-center md:px-0 md:text-left" as="p">
            Nuestra gran experiencia nos permite crear contenido fácil y rápido de entender.
          </H5>
        </div>

        <div className="col-span-full mt-12 flex justify-center lg:col-span-6 lg:col-start-7">
          <div className="flex justify-center ">
            <img
              className="rounded-lg object-cover"
              {...getImgProps(images.understandSolveIssue, {
                widths: [256, 550, 700, 900, 1300, 1800],
                sizes: [
                  '(max-width: 320px) 256px',
                  '(min-width: 321px) and (max-width: 1023px) 80vw',
                  '(min-width: 1024px) 410px',
                  '850px'
                ]
              })}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export { UnderstandSolveIssueSection };

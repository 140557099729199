import { useSearchParams } from '@remix-run/react';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { FullScreenYouTubeEmbed, LiteYouTubeEmbed } from '~/components/fullscreen-yt-embed.tsx';
import { Grid } from '~/components/grid.tsx';
import { H2, H4 } from '~/components/typography.tsx';
import { getImgProps, images } from '~/images.tsx';

function RealVideosSection() {
  const [searchParams] = useSearchParams();
  return (
    <>
      <div className="flex justify-center lg:pb-20">
        <H2 className="max-w-5xl text-center">
          Aprende todo lo que necesitas saber de tu zona íntima con imágenes y videos reales
        </H2>
      </div>
      <Grid>
        <div className="order-2 col-span-full mt-8 flex justify-center md:order-1 md:mt-0 lg:col-span-4 lg:items-center">
          <div>
            <FullScreenYouTubeEmbed
              autoplay={searchParams.has('autoplay')}
              img={
                <img
                  {...getImgProps(images.realVideos, {
                    widths: [256, 550, 700, 900, 1300, 1800],
                    sizes: [
                      '(max-width: 320px) 256px',
                      '(min-width: 321px) and (max-width: 1023px) 80vw',
                      '(min-width: 1024px) 410px',
                      '850px'
                    ]
                  })}
                  className="rounded-lg object-cover"
                />
              }
              ytLiteEmbed={
                <LiteYouTubeEmbed
                  id="a7VxBwLGcDE"
                  announce="Watch"
                  title="Videos reales"
                  // We don't show the poster, so we use the lowest-res version
                  poster="default"
                  params={new URLSearchParams({
                    color: 'white',
                    playsinline: '0',
                    rel: '0'
                  }).toString()}
                />
              }
            />
            <p className="text-secondary text-sm md:text-xl">{`Video introducción (2:13)`}</p>
          </div>
        </div>
        <div className="order-1 col-span-full mt-12 text-center md:order-2 md:text-left lg:col-span-6 lg:col-start-6 lg:mt-0">
          <H2 className="text-center md:text-left">Videos reales</H2>
          <H4 variant="secondary" as="p" className="mt-8 px-8 text-center md:px-0 md:text-left">
            Videos reales de problemas reales que afectan a muchas mujeres igual que a ti. Descubre y entiende al fin
            qué es lo que pasa realmente en tu zona vaginal.
          </H4>
          <ArrowLink to="/about" direction="right" className="mt-3 md:mt-12" prefetch="intent">
            Ver videos
          </ArrowLink>
        </div>
      </Grid>
    </>
  );
}

export { RealVideosSection };

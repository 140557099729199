import { json, type LoaderFunction } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { BlogSection } from '~/components/sections/blog-section.tsx';
import { HeroSection } from '~/components/sections/hero-section.tsx';
import { InstructiveBlogSection } from '~/components/sections/instructive-blog-section.tsx';
import { IntroductionSection } from '~/components/sections/introduction-section.tsx';
import { RealVideosSection } from '~/components/sections/real-videos.tsx';
import { SecondActionSection } from '~/components/sections/second-action-section.tsx';
import { UnderstandSolveIssueSection } from '~/components/sections/understand-solve-issue.tsx';
import { Spacer } from '~/components/spacer.tsx';
import { images } from '~/images.tsx';
import { type MdxListItem } from '~/types.tsx';
import { getBlogRecommendations } from '~/utils/blog.server.ts';
import { getServerTimeHeader } from '~/utils/timing.server.ts';

type LoaderData = {
  blogRecommendations: Array<MdxListItem>;
};

export const loader: LoaderFunction = async ({ request }) => {
  const timings = {};
  const [blogRecommendations] = await Promise.all([getBlogRecommendations({ request, timings })]);

  const data: LoaderData = {
    blogRecommendations
  };
  return json(data, {
    headers: {
      'Cache-Control': 'private, max-age=3600',
      Vary: 'Cookie',
      'Server-Timing': getServerTimeHeader(timings)
    }
  });
};

export default function IndexRoute() {
  const data = useLoaderData<LoaderData>();
  return (
    <>
      <HeroSection
        title={
          <>
            <span className="text-center text-4xl text-white md:text-8xl">Conoce tu zona íntima</span>
            <br /> <span className="text-4xl text-white md:text-8xl">con videos reales</span>
            <br /> <span className="text-2xl font-light text-white md:text-7xl">en nuestro contenido PREMIUM</span>
          </>
        }
        imageBuilder={images.homepage}
        imageSize="large"
        action={
          <div className="flex flex-col gap-4 lg:mr-auto">
            {/* <ButtonLink to="/blog" variant="primary" prefetch="intent" size="large">
              ADQUIERE TU ACCESO ILIMITADO AHORA
            </ButtonLink> */}
          </div>
        }
        rootClassName="bg-orange-200 dark:bg-transparent"
      />
      <main>
        <SecondActionSection />
        <Spacer size="sm" />
        <IntroductionSection />
        <Spacer size="sm" />
        <RealVideosSection />
        <InstructiveBlogSection />
        <Spacer size="sm" />
        <UnderstandSolveIssueSection />
        <Spacer size="sm" />
        <BlogSection
          articles={data.blogRecommendations}
          title="Artículos Recomandados"
          description="Especialmente preparados para ti"
        />
      </main>
    </>
  );
}

import { useSearchParams } from '@remix-run/react';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { FullScreenYouTubeEmbed, LiteYouTubeEmbed } from '~/components/fullscreen-yt-embed.tsx';
import { Grid } from '~/components/grid.tsx';
import { H2, H4 } from '~/components/typography.tsx';
import { getImgProps, images } from '~/images.tsx';

function IntroductionSection() {
  const [searchParams] = useSearchParams();
  return (
    <Grid>
      <div className="col-span-full flex items-center justify-center lg:col-span-3">
        <div>
          <FullScreenYouTubeEmbed
            autoplay={searchParams.has('autoplay')}
            img={
              <img
                {...getImgProps(images.getToKnowCristian, {
                  widths: [256, 550, 700, 900, 1300, 1800],
                  sizes: [
                    '(max-width: 320px) 256px',
                    '(min-width: 321px) and (max-width: 1023px) 80vw',
                    '(min-width: 1024px) 410px',
                    '850px'
                  ]
                })}
                className="rounded-lg object-cover"
              />
            }
            ytLiteEmbed={
              <LiteYouTubeEmbed
                id="riAtOyJBUqw"
                announce="Watch"
                title="Conoce más al Dr. Cristian Hidalgo  - Ginecologo"
                // We don't show the poster, so we use the lowest-res version
                poster="default"
                params={new URLSearchParams({
                  color: 'white',
                  playsinline: '0',
                  rel: '0'
                }).toString()}
              />
            }
          />
          <p className="text-secondary text-sm md:text-xl">{`Video introducción (2:13)`}</p>
        </div>
      </div>
      <div className="col-span-full mt-12 text-center md:text-left lg:col-span-7 lg:col-start-5 lg:mt-0">
        <H2 id="intro" className="text-center md:text-left">
          ¡Hola! Soy el Dr. Cristian Hidalgo. Mi vocación es ayudar a mis pacientes.
        </H2>
        <H4 variant="secondary" as="p" className="mt-8 px-8 text-center md:px-0 md:text-left">
          Gracias a mi experiencia tratando a muchas mujeres con problemas ginecológicos, nació la idea de ayudarlas a
          entender cómo funciona su zona íntima de manera completa y fácil a través de imágenes reales y así lograr que
          tengan un mejor control del cuidado de su salud íntima.
        </H4>
        <ArrowLink to="/about" direction="right" className="mt-3 md:mt-12" prefetch="intent">
          Saber más ...
        </ArrowLink>
      </div>
    </Grid>
  );
}

export { IntroductionSection };

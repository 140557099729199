import { ArrowLink } from '~/components/arrow-button.tsx';
import { Grid } from '~/components/grid.tsx';
import { H2, H4 } from '~/components/typography.tsx';
import { getImgProps, images } from '~/images.tsx';

function InstructiveBlogSection() {
  return (
    <Grid>
      <div className="align-center col-span-full  mt-12 text-center md:text-left lg:col-span-5 lg:mt-0">
        <H2 className="mt-12 text-center md:text-left">Blog instructivo</H2>
        <H4 variant="secondary" className="mt-8 px-8 text-center md:px-0 md:text-left" as="p">
          Lee nuestro blog y entiende cómo se origina una infección vaginal con fotos reales, averigua las causas,
          síntomas, mitos, apariencia, qué hacer y mucho más.
        </H4>

        <ArrowLink to="/blog" direction="right" className="mt-3 md:mt-12" prefetch="intent">
          Ver mas ...
        </ArrowLink>
      </div>

      <div className="col-span-full mt-12 flex justify-center  lg:col-span-6 lg:col-start-7">
        <div>
          <img
            className="focus-ring w-full rounded-lg object-cover object-center"
            {...getImgProps(images.instructiveBlog, {
              widths: [256, 550, 700, 900, 1300, 1800],
              sizes: [
                '(max-width: 320px) 256px',
                '(min-width: 321px) and (max-width: 1023px) 80vw',
                '(min-width: 1024px) 410px',
                '850px'
              ]
            })}
          />
        </div>
      </div>
    </Grid>
  );
}

export { InstructiveBlogSection };
